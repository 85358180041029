import React, { useEffect, useRef, useState } from "react";
import { makePostCall } from "../firebase/user.utils";
import classes from "./bhtform.module.css";
import { Link } from "react-router-dom";
import BackArrowImg from "../assests/backArrow.svg";
import { withRouter } from "react-router-dom";
import BHTlogo from "../assests/bht/055555555555555.png";
import Modal from "react-bootstrap/Modal";
import successPopUp from "../assests/bht/donate.png";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MonthlyPdfGenerator from "./bhtMonthlyPdf.component";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { autocomplete } from "getaddress-autocomplete";
import Footer from "./footer";
import downloadicon from '../assests/downloadicon.png'
import moment from "moment";

const Bhtformmonthly = () => {
  const initialState = {
    Name: "",
    SpouseName: "",
    Address: "",
    Adults: "",
    Children: "",
    Postcode: "",
    ContactNumber: "",
    Email: "",
    ConfirmEmail: "",
    FieldOfExpertise: "",
    WillingToAssistFieldOfExpertise: "Yes",
    InterestInCommitteeActivities: [],
    DonationAmount: "",
    Signature: "",
    Date: "",
    PrintName: "",
    // showSuccessMessage: false,
    // showErrorMessage: false,
  };
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [opencreateQuickLink, setOpencreateQuickLink] = useState(true);
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [pdfconfirmation, setPdfconfirmation] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sign, setSign] = useState();
  const [url, setUrl] = useState("");
  const [Addresss, setAddresss] = useState("");
  const [formData, setFormData] = useState({});
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState([]);
  const [Addresss1, setAddresss1] = useState("");
  const [Addresss2, setAddresss2] = useState("");
  const [Addresss3, setAddresss3] = useState("");
  const [Addresss4, setAddresss4] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("");
  const [signature, setSignature] = useState(""); // Manage signature directly in state
  const [isSignatureCleared, setIsSignatureCleared] = useState(false);
  const signRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  let dataLoad = [];
  let suggData = [];

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const onCloseModal1 = () => {
    setSuccessChangePassword(false);
    setPdfconfirmation(false)
  };
  const handleYes = () => {
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    const data = {
      Name: form.Name,
      SpouseName: form.SpouseName,
      Address: [
        Addresss1,
        Addresss2,
        Addresss3,
        Addresss4,
        district,
        country,
      ],
      Adults: form.Adults,
      Children: form.Children ? form.Children : 0,
      Postcode: postcode,
      ContactNumber: form.ContactNumber,
      Email: form.Email,
      ConfirmEmail: form.ConfirmEmail,
      FieldOfExpertise: form.FieldOfExpertise,
      WillingToAssistFieldOfExpertise: form.WillingToAssistFieldOfExpertise,
      InterestInCommitteeActivities: form.InterestInCommitteeActivities,
      DonationAmount: "£" + form.DonationAmount,
      Signature: trimmedSignature,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      PrintName: form.PrintName,
      pdf: "yes"
    };
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    MonthlyPdfGenerator(dataLoad);
    setPostcode("");
    setAddress("");
    setAddresss1("");
    setAddresss2("");
    setAddresss3("");
    setAddresss4("");
    setDistrict("");
    setCountry("");
    // setField("WillingToAssistFieldOfExpertise", "");
    setField("InterestInCommitteeActivities", "");

    setForm(initialState);
    // sign.clear();
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature")
    setIsSignatureCleared(true);
    setAddresss("");
    setSuccessChangePassword(true);
    setSelectedDate("");
  };

  const handleNo = () => {

    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    const data = {
      Name: form.Name,
      SpouseName: form.SpouseName,
      Address: [
        Addresss1,
        Addresss2,
        Addresss3,
        Addresss4,
        district,
        country,
      ],
      Adults: form.Adults,
      Children: form.Children ? form.Children : 0,
      Postcode: postcode,
      ContactNumber: form.ContactNumber,
      Email: form.Email,
      ConfirmEmail: form.ConfirmEmail,
      FieldOfExpertise: form.FieldOfExpertise,
      WillingToAssistFieldOfExpertise: form.WillingToAssistFieldOfExpertise,
      InterestInCommitteeActivities: form.InterestInCommitteeActivities,
      DonationAmount: "£" + form.DonationAmount,
      Signature: trimmedSignature,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      PrintName: form.PrintName,
      pdf: "no"
    };
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    MonthlyPdfGenerator(dataLoad);
    setPostcode("");
    setAddress("");
    setAddresss1("");
    setAddresss2("");
    setAddresss3("");
    setAddresss4("");
    setDistrict("");
    setCountry("");
    // setField("WillingToAssistFieldOfExpertise", "");
    setField("InterestInCommitteeActivities", "");

    setForm(initialState);
    // sign.clear();
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature")
    setIsSignatureCleared(true);
    setAddresss("");
    setSuccessChangePassword(true);
    setSelectedDate("");
  };

  const handleClear = (event) => {
    event.preventDefault();
    if (signRef.current) {
      signRef.current.clear();
    }
    setSignature("");
    localStorage.removeItem("signature")
    setIsSignatureCleared(true);
  };


  const handleGenerate = (event) => {
    event.preventDefault();
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);
    setIsSignatureCleared(false) // Save signature to local storage
    return toast.success("Signature Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    // Load the signature data from local storage when the component mounts
    const storedSignature = localStorage.getItem("signature");
    if (storedSignature) {
      setSignature(storedSignature);
    }

    // Add event listener for window resize
    const handleResize = () => {
      // Re-render the signature on resize
      if (signRef.current) {
        signRef.current.fromDataURL(signature);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, [signature]); // Re-run the effect when the signature changes
  const handlePostcodeChange = (event) => {
    setPostcode(event.target.value);
  };
  const validateForm = () => {
    const {
      Name,
      SpouseName,
      ContactNumber,
      Email,
      Adults,
      ConfirmEmail,
      FieldOfExpertise,
      WillingToAssistFieldOfExpertise,
      InterestInCommitteeActivities,
      DonationAmount,
      PrintName,
    } = form;
    const newErrors = {};

    if (!Name || Name === "") {
      return toast.error("Please enter Name.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!SpouseName || SpouseName === "") {
      return toast.error("Please enter SpouseName.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Email || Email === "") {
      return toast.error("Please enter Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Email.includes("@")) {
      return toast.error("Please enter valid Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ConfirmEmail || ConfirmEmail === "") {
      return toast.error("Please enter ConfirmEmail.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ConfirmEmail.includes("@")) {
      return toast.error("Please enter valid ConfirmEmail.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (Email !== ConfirmEmail) {
      return toast.error("Email Doesn't Match.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ContactNumber || ContactNumber === "") {
      return toast.error("Please enter ContactNumber.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!postcode || postcode === "") {
      return toast.error("Please enter  Postcode.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Addresss1 || Addresss1 === "") {
      return toast.error("Please enter  Address.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // if (!district || district === "") {
    //   return toast.error("Please enter  county.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    if (!country || country === "") {
      return toast.error("Please enter  country.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Adults || Adults === "") {
      return toast.error("Please enter  Adults.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!FieldOfExpertise || FieldOfExpertise === "") {
      return toast.error("Please enter FieldOfExpertise.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // if (
    //   !WillingToAssistFieldOfExpertise ||
    //   WillingToAssistFieldOfExpertise === ""
    // ) {
    //   return toast.error("Please select WillingToAssistFieldOfExpertise.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    // if (
    //   !InterestInCommitteeActivities ||
    //   InterestInCommitteeActivities === ""
    // ) {
    //   return toast.error("Please select InterestInCommitteeActivities.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    if (!DonationAmount || DonationAmount === "") {
      return toast.error("Please enter DonationAmount.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // if (!signature || signature === "") {
    //   return toast.error("Please enter Signature.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    if (!selectedDate || selectedDate === "") {
      return toast.error("Please select Date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!PrintName || PrintName === "") {
      return toast.error("Please select PrintName.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    return newErrors;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleCheckboxChange = (value) => {
    const updatedCommittees = [...form.InterestInCommitteeActivities];

    if (updatedCommittees.includes(value)) {
      // Remove if already selected
      updatedCommittees.splice(updatedCommittees.indexOf(value), 1);
    } else {
      // Add if not selected
      updatedCommittees.push(value);
    }

    setForm({
      ...form,
      InterestInCommitteeActivities: updatedCommittees,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Exit function if there are validation errors
    }

    setIsLoading(true); // Start loading state

    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const emptySignaturePlaceholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

    if (!trimmedSignature || trimmedSignature === emptySignaturePlaceholder) {
      setIsLoading(false); // Reset loading if signature is empty
      return toast.error("Signature is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setSignature(trimmedSignature);
    localStorage.setItem("signature", trimmedSignature);

    // Define success and failure callbacks for API call
    const onSuccess = (data) => {
      if (data.Status === "Success") {
        setPdfconfirmation(true);
        setResult(data.data);
        setOpencreateQuickLink(true); // Set state or handle as needed
      } else {
        setErrorMessage(true); // Handle error case
      }
    };

    const onFailure = (error) => {
      console.error('API call failed:', error);
      setErrorMessage(true); // Handle error case
    };

    try {
      // Prepare data for API call
      const data = {
        Name: form.Name,
        SpouseName: form.SpouseName,
        Address: [
          Addresss1,
          Addresss2,
          Addresss3,
          Addresss4,
          district,
          country,
        ],
        Adults: form.Adults,
        Children: form.Children ? form.Children : 0,
        Postcode: postcode,
        ContactNumber: form.ContactNumber,
        Email: form.Email,
        ConfirmEmail: form.ConfirmEmail,
        FieldOfExpertise: form.FieldOfExpertise,
        WillingToAssistFieldOfExpertise: form.WillingToAssistFieldOfExpertise,
        InterestInCommitteeActivities: form.InterestInCommitteeActivities,
        DonationAmount: "£" + form.DonationAmount,
        Signature: trimmedSignature,
        Date: moment(selectedDate).format("YYYY-MM-DD"),
        PrintName: form.PrintName,
      };
      dataLoad.push(data);

      // Make API call
      await makePostCall("/gac/gacMonthlyBHT", data)
        .then(onSuccess)
        .catch(onFailure);
    } catch (error) {
      console.error('Error during API call:', error);
      setErrorMessage(true); // Handle error case
    } finally {
      setIsLoading(false); // Ensure loading state is stopped in all cases
    }
  };
  useEffect(() => {
    setAddress(address); // Check the value of address when it changes
  }, [address]);

  const selectedAddressField = async (selected) => {
    if (selected) {
      try {
        const response = await axios.get(
          `https://api.getAddress.io/get/${selected[0]?.id}?api-key=2aubcWJT7k2tZB8s-871FA40125`,
          {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        suggData.push(response.data);

        setAddresss1(suggData[0]?.formatted_address[0]);
        setAddresss2(suggData[0]?.formatted_address[1]);
        setAddresss3(suggData[0]?.formatted_address[2]);
        setAddresss4(suggData[0]?.formatted_address[3]);
        setDistrict(suggData[0]?.county);
        setCountry(suggData[0]?.country);
      } catch (error) { }
    }
  };
  const handleAddressLookup = async (e) => {
    e.preventDefault();
    if (postcode === "") {
      return toast.error("Please enter  Postcode.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    try {
      const response = await axios.post(
        `https://api.getAddress.io/autocomplete/${postcode}?api-key=2aubcWJT7k2tZB8s-871FA40125`,
        {
          all: true,
          mode: "cors",
          headers: {
            // "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (response.data.suggestions.length === 0) {
        return toast.error(
          "Invalid PostCode or Cannot Find the Address. Please enter manually",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
      const data = response.data.suggestions;
      setAddress([...data]);
    } catch (error) { }
  };

  return (
    <>
      <div className={classes.title}>
        <h5>
          <Link to={"/"} className={classes[`BHT-title`]}>
            <img src={BackArrowImg} alt="back-arrow-icon" height={15} />
            <span>back to Home</span>
          </Link>
        </h5>
      </div>
      {successChangePassword ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={successPopUp}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Thank you!</span>
              <p className={classes[`success-modal-desc`]}>
                Monthly Donor Form submitted successfully.
              </p>
              {/* Link to another form */}
              <p className={classes[`success-modal-desc`]}>
                Please do not forget to fill the <a href="http://test-bhtapp-code.s3-website.eu-west-2.amazonaws.com/bhtDeclaration">Donor Gift Aid Form</a>
              </p>
              {/* Link to another form */}
              <p className={classes[`success-modal-desc`]}>
                Dive into another form: <a href="https://bhtapps.co.uk/bhtDeclaration">Donor Gift Aid Form</a>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {pdfconfirmation ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          // onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={downloadicon}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Would you like to download the PDF?</span>
              {/* Add buttons for Yes and No */}
              <div className={classes[`button-container`]}>
                <button onClick={handleYes} className={classes[`confirm-btn`]}>Yes</button>
                <button onClick={handleNo} className={classes[`confirm-btn`]}>No</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Form>
        <div className={classes.formWrapper}>
          <div className={classes.bhtform}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img src={BHTlogo} />
              </div>
              <h1 className={classes.heading}>
                BEDFORD HINDU TEMPLE AND COMMUNITY TRUST
              </h1>
            </div>
            <div>
              <div className={classes[`monthly-form_title`]}>
                <p>Monthly Donor Form </p>
              </div>

              <div className={!!errors.Amount && classes.error}>
                {errors.Amount}
              </div>

              <div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="name" className={classes.fgInput}>
                      Name
                    </label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={form.Name}
                      onChange={(e) => setField("Name", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="spousename" className={classes.fgInput}>
                      Spouse's Name
                    </label>
                    <input
                      type="text"
                      placeholder="Spouse Name"
                      name="spousename"
                      value={form.SpouseName}
                      onChange={(e) => setField("SpouseName", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="email" className={classes.fgInput}>
                      Email
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={form.Email}
                      onChange={(e) => setField("Email", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="confirmemail" className={classes.fgInput}>
                      Confirm Email
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Confirm Email"
                      name="confirmemail"
                      value={form.ConfirmEmail}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => setField("ConfirmEmail", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="ContactNumber" className={classes.fgInput}>
                      Contact Number
                    </label>
                    <br></br>
                    <input
                      type="text"
                      maxLength="14"
                      placeholder="Contact Number"
                      name="ContactNumber"
                      value={form.ContactNumber}
                      onChange={(e) =>
                        setField("ContactNumber", e.target.value?.replace(/[^0-9]/g, ''))
                      }
                      className={classes.formInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="Address" className={classes.fgInput}>
                      PostCode
                    </label>
                    <br></br>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="Enter PostCode "
                        value={postcode}
                        onChange={handlePostcodeChange}
                        className={classes.formInput}
                      />
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "30%",
                          border: "2px solid black",
                          backgroundColor: "#f0f0f0",
                          height: "35px",
                          fontSize: "10px",
                          whiteSpace: "wrap",
                          padding: " 0",
                          cursor: "pointer",
                        }}
                        onClick={handleAddressLookup}
                      >
                        Find Address
                      </span>
                    </div>
                    {address.length > 0 && (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="address"
                        onChange={selectedAddressField}
                        options={address}
                        placeholder="Please select Address"
                      />
                    )}
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput}>Address1</label>
                    <br></br>
                    <div>
                      <input
                        value={Addresss1}
                        onChange={(e) => setAddresss1(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="Address2">
                      Address2
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={Addresss2}
                        onChange={(e) => {
                          setAddresss2(e.target.value);
                        }}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="county" className={classes.formInputNoAsterik}>
                      County
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="country" className={classes.fgInput}>
                      Country
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.fieldOfExpertise}>
                  <div className={classes.inputFamilyMembersWrapper}>
                    <div className={classes.otherMembersLbl}>
                      <p>Other members in your family:</p>
                    </div>
                    <div className={classes.otherMembers}>
                      <div className={classes.childrenSec}>
                        <label htmlFor="Adults" className={classes.fgInput}>
                          Adults
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          name="adults"
                          value={form.Adults}
                          onChange={(e) => setField("Adults", e.target.value?.replace(/[^0-9]/g, ''))}
                        // className={classes.formInput}
                        />
                      </div>

                      <div className={classes.childrenSec}>
                        <label htmlFor="Children" className={classes.formInputNoAsterik}>Children</label>
                        <input
                          type="text"
                          name="Children"
                          value={form.Children}
                          onChange={(e) => setField("Children", e.target.value)}
                        // className={classes.formInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.expertiseSec}>
                    <label
                      htmlFor="fieldofexpertise"
                      className={classes.fgInput}
                    >
                      You/your spouse's selected field of expertise:
                    </label>
                    <div>
                      <input
                        name="fieldofexpertise"
                        value={form.FieldOfExpertise}
                        onChange={(e) =>
                          setField("FieldOfExpertise", e.target.value)
                        }
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.radioGroupWrapper}>
                  <p>
                    Would you/your spouse be willing to assist The Trust in your
                    field of expertise:
                  </p>

                  <div className={classes.radioGroup}>
                    <input
                      type="radio"
                      name="choice"
                      defaultChecked={
                        form.WillingToAssistFieldOfExpertise === "Yes"
                      }
                      value={form.WillingToAssistFieldOfExpertise}
                      onChange={(e) =>
                        setField("WillingToAssistFieldOfExpertise", "Yes")
                      }
                      className={classes.formInput}
                    />
                    <label htmlFor="county" className={classes.radioLbl}>
                      Yes
                    </label>
                  </div>

                  <div className={classes.radioGroup}>
                    <input
                      type="radio"
                      id="noCheckbox"
                      name="choice"
                      value={form.WillingToAssistFieldOfExpertise}
                      onChange={(e) =>
                        setField("WillingToAssistFieldOfExpertise", "No")
                      }
                    />
                    <label htmlFor="country" className={classes.radioLbl}>
                      No
                    </label>
                  </div>
                </div>
                <div>
                  <p>
                    Please indicate your interest in joining one of the
                    following committees that you would like to associate with
                    and help with the Temple & Community activities:
                  </p>

                  <input
                    type="checkbox"
                    id="membership"
                    name="committee[]"
                    value="Membership & Fund-raising"
                    checked={form.InterestInCommitteeActivities.includes(
                      "Membership & Fund-raising"
                    )}
                    onChange={(e) =>
                      handleCheckboxChange("Membership & Fund-raising")
                    }
                  />
                  <label htmlFor="membership" className={classes.lblname}>
                    Membership & Fund-raising
                  </label>

                  <input
                    type="checkbox"
                    id="pooja"
                    name="committee[]"
                    value="Pooja & Religious events"
                    checked={form.InterestInCommitteeActivities.includes(
                      "Pooja & Religious events"
                    )}
                    onChange={(e) =>
                      handleCheckboxChange("Pooja & Religious events")
                    }
                  />
                  <label htmlFor="pooja" className={classes.lblname}>
                    Pooja & Religious events
                  </label>
                  <input
                    type="checkbox"
                    id="cultural"
                    name="committee[]"
                    value="Cultural events"
                    checked={form.InterestInCommitteeActivities.includes(
                      "Cultural events"
                    )}
                    onChange={(e) => handleCheckboxChange("Cultural events")}
                  />
                  <label htmlFor="cultural" className={classes.lblname}>
                    Cultural events
                  </label>
                  <input
                    type="checkbox"
                    id="administration"
                    name="committee[]"
                    value="administration"
                    checked={form.InterestInCommitteeActivities.includes(
                      "Administration"
                    )}
                    onChange={(e) => handleCheckboxChange("Administration")}
                  />
                  <label htmlFor="administration" className={classes.lblname}>
                    Administration
                  </label>

                  {/* Add other checkboxes similarly... */}

                  <label className={classes.lblname}>
                    Others (Please specify)
                  </label>
                  <input
                    className={classes.formInputActivities}
                    type="text"
                    id="otherCommittee"
                    name="committee_other"
                    value={form.InterestInCommitteeActivities}
                    onChange={(e) =>
                      setField("InterestInCommitteeActivities", e.target.value)
                    }
                  />
                </div>
                <div className={classes.bankDetailsWrapper}>
                  <span className={classes.bankDetailsHeading}>
                    Trust's Bank details:
                  </span>
                  <div className={classes[`bank-details`]}>
                    Account name:
                    <strong>Bedford Hindu Temple & Community Trust</strong>{" "}
                    <br /> Bank: <strong>Lloyds Bank</strong> <br /> Account
                    Number:
                    <strong>73419360</strong> <br />
                    Sort Code: <strong>30-98-97</strong>
                  </div>
                </div>
                <div className={classes.acceptanceSec}>
                  <span>
                    Please enroll my family as a regular monthly donor of the Trust and am willing to set up a standing order
                    to the Temple Trust’s bank account at the commencement of each month to pay a monthly amount of:
                  </span>
                  <input
                    type="radio"
                    id="amount15"
                    name="donation_amount"
                    value="15"
                    onChange={(e) => setField("DonationAmount", e.target.value)}
                    checked={form.DonationAmount === "15"}
                  />
                  <label htmlFor="amount15" className={classes.lblname}>
                    £15
                  </label>
                  <input
                    type="radio"
                    id="amount20"
                    name="donation_amount"
                    value="20"
                    onChange={(e) => setField("DonationAmount", e.target.value)}
                    checked={form.DonationAmount === "20"}
                  />
                  <label htmlFor="amount20" className={classes.lblname}>
                    £20
                  </label>
                  <input
                    type="radio"
                    id="amount25"
                    name="donation_amount"
                    value="25"
                    onChange={(e) => setField("DonationAmount", e.target.value)}
                    checked={form.DonationAmount === "25"}
                  />
                  <label htmlFor="amount25" className={classes.lblname}>
                    £25
                  </label>
                  <input
                    type="radio"
                    id="amount50"
                    name="donation_amount"
                    value="50"
                    onChange={(e) => setField("DonationAmount", e.target.value)}
                    checked={form.DonationAmount === "50"}
                  />
                  <label htmlFor="amount50" className={classes.lblname}>
                    £50
                  </label>
                  <label>
                    Other{" "} </label>
                  <input
                    className={classes.donationAmtInput}
                    type="text"
                    id="otherAmount"
                    name="donation_amount_other"
                    value={form.DonationAmount}
                    onChange={(e) =>
                      setField("DonationAmount", e.target.value?.replace(/[^0-9]/g, ''))
                    }
                  />

                </div>
                <p>
                  Now we kindly request you to set up a standing order through your bank for the intendend monthly donation amount.
                </p>
                <p className={classes.giftAidTitle}>
                  DEVOTEES JOINING AS REGULR MONTHLY DONORS TO SUPPORT THE
                  CHARITY’S ACTIVITIES KINDLY USE THE ATTACHED GIFT AID FORM
                </p>
                <p className={classes.giftAidDesc}>
                  (The Trust gratefully appreciates any donation/s to our Hindu
                  Community Centre & Temple endeavor)
                </p>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInputSignature}>Signed</label>
                    <br></br>
                    <div
                      className={classes.signCanvasWrapper}
                      style={{
                        border: "1px solid black",
                        height: 200,
                        marginBottom: "20px",
                      }}
                    >
                      <SignatureCanvas
                        canvasProps={{ className: "signature-pad" }}
                        // ref={(data) => setSign(data)}
                        ref={(ref) => (signRef.current = ref)}
                      />
                      <div className={classes.btngroup}>
                        <button name="clear" onClick={handleClear}>
                          Clear
                        </button>
                        <button name="save" onClick={handleGenerate}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="dateInput" className={classes.fgInput}>
                      Date
                    </label>
                    <br></br>
                    <div>
                      <DatePicker
                        id="dateInput"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        style={{
                          width: "100%",
                          height: "35px",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.inputWrapper}>
                  <label htmlFor="printname" className={classes.fgInput}>
                    Print Name
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Print Name"
                    name="printname"
                    value={form.PrintName}
                    onChange={(e) => setField("PrintName", e.target.value)}
                    className={classes.formPrintNameInput}
                  />
                </div>
                <div className={classes[`consent-sec`]}>
                  {" "}
                  <p className={classes.consentForm}>
                    Please read, complete, sign the Data Usage Consent form under GDPR 2018 guideline & Gift Aid Declaration forms attached <a href='http://test-bhtapp-code.s3-website.eu-west-2.amazonaws.com/bhtDataProtection'>Data Usage Consent form</a> and <a href='http://test-bhtapp-code.s3-website.eu-west-2.amazonaws.com/bhtDeclaration'>Gift Aid Declaration forms</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className={classes.submit}>
          <button
            name="submit"
            type="submit"
            onClick={handleSubmit}
            className={classes[`submit-btn`]}
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <span>Loading...</span> // Replace with your spinner component or text
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </Form>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default withRouter(Bhtformmonthly);
