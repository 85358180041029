import axios from "axios";


export const makePostCall = (path, payload, reload = true) => {
    const accessToken = localStorage.getItem("gac_token");
    const URL = "https://apps.gacdigital.in" + path;
    // console.log(URL)
    return axios(URL, {
        method: "POST",
        headers: {
            "content-type": "application/json", // whatever you want
            // Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify(payload),
    })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};