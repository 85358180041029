import React from "react";
import { Link } from "react-router-dom";
import card from "../assests/bht/latestCard.png";
import monthlyCard from "../assests/Group 730.png"
import dataCard from "../assests/Group 728.png"
import classes from "./bhtform.module.css";
import BHTlogo from "../assests/bht/055555555555555.png";
import Srivenlogo from "../assests/bht/Sriven logo for website.png";

const BHTCard = () => {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={BHTlogo} />
        </div>
        <h1 className={classes.heading}>
          BEDFORD HINDU TEMPLE AND COMMUNITY TRUST
        </h1>
      </div>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
        {" "}
        <div className={classes.card}>
          <img src={card} alt="donation card" />
          <Link to={"/bhtDeclaration"}>
            <h6>Donor Gift Aid Form</h6>
          </Link>
          {/* <p>Charity Gift Aid Declaration - multiple donation</p> */}
        </div>
        <div className={classes.card}>
          <img src={monthlyCard} alt="donation card" />
          <Link to={"/bhtMonthlyDeclaration"}>
            <h6>Monthly Donor Form</h6>
          </Link>
          {/* <p>Charity Gift Aid Declaration - monthly donation</p> */}
        </div>
        <div className={classes.card}>
          <img src={dataCard} alt="donation card" />
          <Link to={"/bhtDataProtection"}>
            <h6>Consent and Data Protection Form</h6>
          </Link>
          {/* <p>Charity Gift Aid Declaration - Data Protection</p> */}
        </div>
      </div>
      <div className={classes.cardFooter}>
        <p>Powered By Sriven Solutions Ltd @2023</p>
        <img src={Srivenlogo} alt="donation card" />
      </div>
    </div>
  );
};

export default BHTCard;
