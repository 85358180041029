import React from "react";
import classes from "./bhtform.module.css";
import emailLogo from "../assests/bht/mailbox-svgrepo-com.png";
import wedsiteLogo from "../assests/bht/world-wide-web-svgrepo-com (1).png";
import addressLogo from "../assests/bht/Path 184.png";
import detailslogo from "../assests/bht/hindu-temple-svgrepo-com.png";
const Footer = () => {
  return (
    <>
      <div className={classes.footer}>
        <div className={classes.para}>
          <p>Contact Us</p>
        </div>
        <div className={classes.footerParaWrapper}>
          <div className={classes.footerPara}>
            <div className={classes.footerDetails}>
              <div className={classes.footerlogo}>
                <img src={wedsiteLogo} />
              </div>
              <p>www.bedfordhindutemple.org</p>
            </div>
            <div className={classes.footerDetails}>
              <div className={classes.footerlogo}>
                <img src={emailLogo} />
              </div>
              <p>bedfordhindutemple@gmail.com</p>
            </div>
          </div>
          <div className={classes.footerPara}>
            <div className={classes.footerDetails}>
              <div className={classes.footerlogo}>
                <img src={addressLogo} />
              </div>
              <p>Registered Charity No. 1185539</p>
            </div>
            <div className={classes.footerDetails}>
              <div className={classes.footerlogo}>
                <img src={detailslogo} />
              </div>
              <p>No. 5, Peel Street, Bedford MK40 2HX</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
