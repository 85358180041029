import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import BhtForm from "./components/bhtform.component";
import BhtMonthlyForm from "./components/bhtformmonthly";
import DataProtectionForm from "./components/dataprotectionfrom.component";
import Card from "./components/card"
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Card} />
        <Route path="/bhtDeclaration" component={BhtForm} />
        <Route path="/bhtMonthlyDeclaration" component={BhtMonthlyForm} />
        <Route path="/bhtDataProtection" component={DataProtectionForm} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;